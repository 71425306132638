import React, { useState } from "react"
import { navigate } from "gatsby"
import { useFormik } from "formik"
import {
  Box,
  Button,
  ScaleFade,
  Flex,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  Text,
} from "@chakra-ui/react"

import { firebase } from "../../lib/firebase/client"
import { Layout } from "../../layouts/default"
import { Heading } from "../Heading"
import { Helmet } from "react-helmet"

export const ResetPasswordForm = () => {
  const [loading, setLoading] = useState(false)
  const [firebaseError, setFirebaseError] = useState(null)
  const toast = useToast()

  const resetPassword = async ({ email, password }) => {
    setLoading(true)

    try {
      await firebase.auth().sendPasswordResetEmail(email)

      setLoading(false)

      navigate("/login")

      toast({
        title: "Reset password email sent.",
        description: `An email was sent to ${email} with a link to help you reset your password`,
        status: "success",
        duration: 9000,
        isClosable: true,
      })
    } catch (err) {
      setFirebaseError(err.message)
      setLoading(false)

      toast({
        title: "Oops there was a problem.",
        description:
          "Sorry we were unable to update your details. Please check the information and try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })

      // Logging
      console.error("Unable to reset password: ", err)
    }
  }

  const formik = useFormik({
    onSubmit: values => resetPassword(values),
    initialValues: {
      email: "",
    },
  })

  return (
    <Layout isLogin>
      <Helmet>
        <title>Micropore Technologies - Reset your password</title>
      </Helmet>
      <Flex
        minHeight="600px"
        width="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        backgroundColor="gray.20"
        pb={14}
      >
        <ScaleFade initialScale={0.9} in={true}>
          <Box
            width="350px"
            boxShadow="0px 0px 14px rgba(0, 0, 0, .15)"
            padding="40px"
            borderRadius="5px"
          >
            {firebaseError && (
              <Box
                bg="red.100"
                color="red.500"
                p={4}
                mb={4}
                borderRadius="5px"
                fontWeight="600"
              >
                {firebaseError}
              </Box>
            )}

            <form onSubmit={formik.handleSubmit}>
              <Heading as="h2" fontSize={24} mb={6}>
                Reset your password
              </Heading>
              <Text
                backgroundColor="gray.100"
                mb={6}
                fontSize={16}
                borderLeftWidth={3}
                borderLeftColor="brand.400"
                px={5}
                py={2}
              >
                Enter your email address in the field below and click the send
                email button. We will send you an email with a link to reset
                your password.
              </Text>

              <VStack spacing="24px">
                <FormControl id="email">
                  <FormLabel htmlFor="email">Email address</FormLabel>
                  <Input
                    type="email"
                    onChange={formik.handleChange}
                    id="email"
                    name="email"
                    value={formik.values.email}
                  />
                </FormControl>

                <Button
                  colorScheme="blue"
                  color="white"
                  size="md"
                  isFullWidth
                  type="submit"
                  disabled={loading}
                  isLoading={loading}
                  loadingText="Please Wait"
                >
                  Send email
                </Button>
              </VStack>
            </form>
          </Box>
        </ScaleFade>
      </Flex>
    </Layout>
  )
}
